import React, { useState, useCallback } from 'react'
import { graphql, Link } from 'gatsby'
import { Box, Heading, Text } from 'rebass/styled-components'
import Layout from '../components/layout'
import Img from '../components/SanityImage'
import { Grid, GridChild } from '../components/GridLayout'
import { dark as darkTheme } from '../theme'
import { ThemeProvider } from 'styled-components'
import useProjects from '../hooks/useProjects'
import { Fade } from 'react-reveal'
import { Waypoint } from 'react-waypoint'

const ProjectItem = ({ node, ...rest }) => (
  <Box {...rest}>
    <Link
      to={`/projects/${node.slug.current}`}
      style={{ textDecoration: 'none' }}
    >
      <Box
        width='100%'
        sx={{
          display: ['block', 'flex'],
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Box width='100%' flexShrink={1}>
          <Img
            node={node._rawPreviewImg || node._rawHeroImg}
            disableMobileResizing={true}
          />
        </Box>
        <Box
          flexShrink={0}
          width={['100%', 2 / 5]}
          mt={['1.5rem', 0]}
          ml={[0, 2]}
        >
          <Heading variant='h5' mb={'0.5rem'} color='beige'>
            {node.title}
          </Heading>
          <Text sx={{ opacity: 0.8 }} color='beige'>
            {node.description || node.heroHeading}
          </Text>
        </Box>
      </Box>
    </Link>
  </Box>
)

const ProjectsPage = props => {
  const {
    data: {
      projectList: { title, projects }
    },
    location
  } = props
  const [entered, setEntered] = useState({})

  return (
    <Layout location={location}>
      <ThemeProvider theme={darkTheme}>
        <Grid
          bg='bgColor'
          minHeight='100vh'
          pt={8}
          pb={10}
          sx={{
            display: 'block'
          }}
        >
          <Box mb={[3, 4]}>
            <Heading as='h2' variant='h2' mb={0}>
              {title || 'Our Work'}
            </Heading>
          </Box>
          {projects.map(({ project: node }, index) => (
            <Waypoint
              key={node.id}
              debug={!entered[node.id]}
              topOffset='90%'
              bottomOffset='90%'
              onEnter={() => setEntered({ ...entered, [node.id]: true })}
            >
              <Box pb={[3, 4]}>
                <Fade
                  bottom
                  when={!!entered[node.id]}
                  // onReveal={e => console.log('REVEAL', node.title)}
                >
                  <Link
                    to={`/projects/${node.slug.current}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Box
                      width='100%'
                      sx={{
                        display: ['block', 'flex'],
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Box width='100%' flexShrink={1}>
                        <Img
                          node={node._rawPreviewImg || node._rawHeroImg}
                          disableMobileResizing={true}
                        />
                      </Box>
                      <Box
                        flexShrink={0}
                        width={['100%', 2 / 5]}
                        mt={['1.5rem', 0]}
                        ml={[0, 2]}
                      >
                        <Heading variant='h5' mb={'0.5rem'} color='beige'>
                          {node.title}
                        </Heading>
                        <Text sx={{ opacity: 0.8 }} color='beige'>
                          {node.description || node.heroHeading}
                        </Text>
                      </Box>
                    </Box>
                  </Link>
                </Fade>
              </Box>
            </Waypoint>
          ))}
        </Grid>
      </ThemeProvider>
    </Layout>
  )
}

export const query = graphql`
  query ProjectsPageQuery {
    projectList: sanityProjectList(_id: { regex: "/(drafts.|)projectList/" }) {
      title
      projects {
        project {
          ...ProjectInfo
        }
      }
    }
  }
`

export default ProjectsPage
